import React from "react";
import "../css/footer.css";
import { Link } from "react-router-dom";

function Footer() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="bottom-container">
        <div className="footer_inner_first">
          <img
            width="97"
            height="97"
            alt=""
            src="https://static.wixstatic.com/media/32eba4_1dee72b4d5ad4df582d8124d15da1326~mv2.png/v1/fill/w_97,h_97,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/SHOWROOMINGNEW%E3%83%AD%E3%82%B4.png"
          />
          <div className="footer_inner_first_first_txt">
            <p className="d_p">3Dインテリアコーディネート</p>
            <p className="d_p_2">オンラインインテリアコーディネート</p>
            <p className="d_p_3">SHOWROOMING</p>
          </div>

          <div className="footer_inner_first_sec_img">
            <img
              alt=""
              src="https://static.wixstatic.com/media/e1aa082f7c0747168d9cf43e77046142.png/v1/fill/w_50,h_50,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/e1aa082f7c0747168d9cf43e77046142.png"
            />
            <img
              alt=""
              src="https://static.wixstatic.com/media/45bce1d726f64f1999c49feae57f6298.png/v1/fill/w_50,h_50,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/45bce1d726f64f1999c49feae57f6298.png"
            />
            <img
              alt=""
              src="https://static.wixstatic.com/media/11062b_66167d61316d4665841f3397360d54a9~mv2.png/v1/fill/w_50,h_50,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/11062b_66167d61316d4665841f3397360d54a9~mv2.png"
            />
          </div>

          <div className="footer_inner_first_third_txt">
            <p className="connect_p">
              【ご相談無料】まずはお気軽に連絡ください
            </p>
            <p className="mail_foot">
              ​MALE：
              <a href="team@goagoa.click">team@goagoa.click</a>
            </p>
          </div>
        </div>

        <div className="footer_inner_sec">
          <ul>
            <li>
              <Link to="/" onClick={toTop}>
                ▶Home
              </Link>
            </li>
            <li>
              <Link to="/terms" onClick={toTop}>
                ▶利用規約
              </Link>
            </li>
            <li>
              <Link to="/privacy" onClick={toTop}>
                ▶プライバシーポリシー
              </Link>
            </li>
            <li>
              <Link to="/particular" onClick={toTop}>
                ▶特定商取引法に基づく表記
              </Link>
            </li>
          </ul>
        </div>

        <p className="footer_copyright">Copyright © 2021 CO2create,inc</p>
      </div>
    </>
  );
}

export default Footer;
