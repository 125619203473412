const productData = [
  {
    id: 1,
    name: "【個人宅】オンラインコーディネート",
    details:
      "インテリアのプロスタッフが、レイアウトプラン・コーディネートポイント・アイテムリストをプランニングしお届けいたします",
    price: "3900",
    list: [
      "【プライベートトークルーム開設】サービス購入後に専用LINEページをアナウンス",
      "【レイアウトプラン作成】ご自宅の間取りを図に家具をレイアウト",
      "【コーディネートポイント作成】コーディネート内容をまとめてお届け",
      "【アイテムリスト作成】購入可能な商品ＵＲＬ付き",
    ],
  },
  {
    id: 2,
    name: "【個人宅】３Dインテリアコーディネート",
    details:
      "あなたのお部屋を３Dで再現し、３Dで再現されたアイテムリストのご提案家具をレイアウトしプランニングいたします。",
    price: "8900",
    list: [
      "【プライベートトークルーム開設】サービス購入後に専用LINEページをアナウンス",
      "【3Dレイアウトプラン作成】ご自宅の間取りを３D図に家具をレイアウト",
      "【コーディネートポイント作成】コーディネート内容をまとめてお届け",
      "【３Dインテリアイメージ】再現されたお部屋に再現された家具を３Dでお届け",
      "【アイテムリスト作成】購入可能な商品ＵＲＬ付き",
    ],
  },
  {
    id: 3,
    name: "【個人・法人用】レンタルスペースプラン",
    details:
      "レンタルスペース室内を３Dで再現し、３Dで再現されたアイテムリストとご提案家具をレイアウトしプランニングいたします。",
    price: "10900",
    list: [
      "【プライベートトークルーム開設】サービス購入後に専用LINEページをアナウンス",
      "【3D間取り図作成】スペースの間取りを３D図にし家具をレイアウト",
      "【３Dインテリアイメージ】再現されたお部屋に再現された家具を３Dでお届け",
      "【アイテムリスト作成】購入可能な商品ＵＲＬ付き",
      "【コーディネートポイント作成】コーディネート内容をまとめてお届け",
    ],
  },
];

export default productData;
