import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from "./Component/Main";
import { CartProvider } from "./Component/CartContext";
import Privacy from "./Component/Privacy";
import About from "./Component/About";
import Particular from "./Component/Particular";
import Terms from "./Component/Terms";
import Service from "./Component/Service";
import CheckOut from "./Component/CheckOut";
import Success from "./Component/SuccessPay";

function App() {
  return (
    <CartProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/service" element={<Service />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/particular" element={<Particular />} />
          <Route path="/about" element={<About />} />
          <Route path="/checkout/:id" element={<CheckOut />} />
          <Route path="/success" element={<Success />} />
        </Routes>
      </Router>
    </CartProvider>
  );
}

export default App;
