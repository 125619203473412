import React, { useState } from "react";
import "../css/checkout.css";
import productData from "../Datas/productData";
import { useParams } from "react-router-dom";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import Header from "./Header";

const CheckOut = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const product = productData.find((item) => item.id === parseInt(id));
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    cardNumber: "",
    cardExpiration: "",
    cardCvv: "",
    cardHolderName: "",
    email: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;

    // 邮箱格式验证
    if (name === "email") {
      const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
      if (!emailPattern.test(value)) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          email: "有効なメールアドレスを入力してください。",
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          email: "",
        }));
      }
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const validateForm = () => {
    const errors = {};
    const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;

    if (!formData.cardNumber.trim())
      errors.cardNumber = "カ一ド番号を入カしてください。";

    if (!formData.cardExpiration.trim())
      errors.cardExpiration = "有効期限を入力してください。";

    if (!formData.cardCvv.trim())
      errors.cardCvv = "CVV/CVCを入力してください。";

    if (!formData.cardHolderName.trim())
      errors.cardHolderName = "カードに記載されている名前を入力してください。";

    if (!formData.email.trim() || !emailPattern.test(formData.email))
      errors.email = "有効なメールアドレスを入力してください。";

    return errors;
  };

  const handlePlaceOrder = (e) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    e.preventDefault();
    const errors = validateForm();
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      navigate("/success");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const handleCardExpirationChange = (e) => {
    let value = e.target.value;
    value = value.replace(/\D/g, "");

    if (value.length >= 3) {
      value = value.slice(0, 2) + "/" + value.slice(2, 4);
    }

    if (value.length > 5) {
      value = value.slice(0, 5);
    }

    setFormData((prev) => ({
      ...prev,
      cardExpiration: value,
    }));
  };

  const handleCardNumberChange = (e) => {
    let value = e.target.value;
    value = value.replace(/\D/g, "");
    if (value.length > 4) {
      value = value
        .match(/.{1,4}/g)
        .join(" ")
        .slice(0, 12);
    }

    setFormData((prev) => ({
      ...prev,
      cardNumber: value,
    }));
  };

  return (
    <>
      <Header />
      <div className="checkout_box_div">
        <div className="checkout_box_inner">
          <h1 className="first_h1">お支払い画面</h1>
          <hr
            data-hook="title-divider"
            className="soidi6e oKiL8GH---direction-10-horizontal sMRgyNF"
          />
          <div className="checkout_flex">
            <div className="checkout_left">
              <h2>お支払い</h2>
              <div className="text_input">
                <div className="card_input">
                  <label>カード番号</label>
                  <div
                    className="card_input_imgs"
                    style={{
                      border: formErrors.cardNumber
                        ? "1px solid rgb(223, 49, 49)"
                        : "",
                    }}
                  >
                    <input
                      id="cardNumber"
                      maxLength="12"
                      pattern="\d{4}\s\d{4}\s\d{2}"
                      placeholder="番号を入力"
                      aria-required="true"
                      data-hook="field-input"
                      name="cardNumber"
                      type="tel"
                      className="sbyRsif smMgyln bF0weP b7khad has-custom-focus"
                      value={formData.cardNumber}
                      onChange={handleCardNumberChange}
                    />
                    <div className="image_card">
                      <img
                        alt=""
                        src="https://cashier-pci.wixapps.net/card-form/resources/cardNetworks/mastercard.svg"
                      />
                      <img
                        alt=""
                        src="https://cashier-pci.wixapps.net/card-form/resources/cardNetworks/visa.svg"
                      />
                      <img
                        alt=""
                        src="https://cashier-pci.wixapps.net/card-form/resources/cardNetworks/amex.svg"
                      />
                      <img
                        alt=""
                        src="https://cashier-pci.wixapps.net/card-form/resources/cardNetworks/maestro.svg"
                      />
                    </div>
                  </div>
                  <div className="fill_div">
                    {formErrors.cardNumber && (
                      <>
                        <svg
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          width="20"
                          height="20"
                          class="sHcGI3k"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M9.5,3 C13.084,3 16,5.916 16,9.5 C16,13.084 13.084,16 9.5,16 C5.916,16 3,13.084 3,9.5 C3,5.916 5.916,3 9.5,3 Z M9.5,4 C6.467,4 4,6.467 4,9.5 C4,12.533 6.467,15 9.5,15 C12.533,15 15,12.533 15,9.5 C15,6.467 12.533,4 9.5,4 Z M10,11 L10,12 L9,12 L9,11 L10,11 Z M10,7 L10,10 L9,10 L9,7 L10,7 Z"
                          ></path>
                        </svg>
                        <span className="error">{formErrors.cardNumber}</span>
                      </>
                    )}
                  </div>
                </div>
                <div className="time_cvv">
                  <div className="card_input">
                    <label>有効期限</label>
                    <div
                      className="card_input_imgs time_cvv_inner"
                      style={{
                        border: formErrors.cardExpiration
                          ? "1px solid rgb(223, 49, 49)"
                          : "",
                      }}
                    >
                      <input
                        id="cardExpiration"
                        maxLength="5"
                        placeholder="MM/YY"
                        aria-required="true"
                        data-hook="field-input"
                        name="cardExpiration"
                        type="tel"
                        className=" smMgyln bF0weP b7khad has-custom-focus"
                        value={formData.cardExpiration}
                        onChange={handleCardExpirationChange}
                      />
                    </div>
                    <div className="fill_div">
                      {formErrors.cardExpiration && (
                        <>
                          <svg
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            width="20"
                            height="20"
                            class="sHcGI3k"
                            aria-hidden="true"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M9.5,3 C13.084,3 16,5.916 16,9.5 C16,13.084 13.084,16 9.5,16 C5.916,16 3,13.084 3,9.5 C3,5.916 5.916,3 9.5,3 Z M9.5,4 C6.467,4 4,6.467 4,9.5 C4,12.533 6.467,15 9.5,15 C12.533,15 15,12.533 15,9.5 C15,6.467 12.533,4 9.5,4 Z M10,11 L10,12 L9,12 L9,11 L10,11 Z M10,7 L10,10 L9,10 L9,7 L10,7 Z"
                            ></path>
                          </svg>
                          <span className="error">
                            {formErrors.cardExpiration}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="card_input">
                    <label>CVV/CVC</label>
                    <div
                      className="card_input_imgs  time_cvv_inner"
                      style={{
                        border: formErrors.cardCvv
                          ? "1px solid rgb(223, 49, 49)"
                          : "",
                      }}
                    >
                      <input
                        id="cardCvv"
                        maxLength="4"
                        pattern="\d{3,5}"
                        placeholder="番号を入力"
                        aria-required="true"
                        data-hook="field-input"
                        name="cardCvv"
                        type="tel"
                        className=" smMgyln bF0weP b7khad has-custom-focus"
                        value={formData.cardCvv}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="fill_div">
                      {formErrors.cardCvv && (
                        <>
                          <svg
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            width="20"
                            height="20"
                            class="sHcGI3k"
                            aria-hidden="true"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M9.5,3 C13.084,3 16,5.916 16,9.5 C16,13.084 13.084,16 9.5,16 C5.916,16 3,13.084 3,9.5 C3,5.916 5.916,3 9.5,3 Z M9.5,4 C6.467,4 4,6.467 4,9.5 C4,12.533 6.467,15 9.5,15 C12.533,15 15,12.533 15,9.5 C15,6.467 12.533,4 9.5,4 Z M10,11 L10,12 L9,12 L9,11 L10,11 Z M10,7 L10,10 L9,10 L9,7 L10,7 Z"
                            ></path>
                          </svg>
                          <span className="error">{formErrors.cardCvv}</span>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div className="card_input">
                  <label>カード名義人</label>
                  <div
                    className="card_input_imgs"
                    style={{
                      border: formErrors.cardHolderName
                        ? "1px solid rgb(223, 49, 49)"
                        : "",
                    }}
                  >
                    <input
                      id="cardHolderName"
                      pattern="[\d\s]*"
                      placeholder="番号を入力"
                      aria-required="true"
                      data-hook="field-input"
                      name="cardHolderName"
                      type="text"
                      className="sbyRsif1 smMgyln bF0weP b7khad has-custom-focus"
                      value={formData.cardHolderName}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="fill_div">
                    {formErrors.cardHolderName && (
                      <>
                        <svg
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          width="20"
                          height="20"
                          class="sHcGI3k"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M9.5,3 C13.084,3 16,5.916 16,9.5 C16,13.084 13.084,16 9.5,16 C5.916,16 3,13.084 3,9.5 C3,5.916 5.916,3 9.5,3 Z M9.5,4 C6.467,4 4,6.467 4,9.5 C4,12.533 6.467,15 9.5,15 C12.533,15 15,12.533 15,9.5 C15,6.467 12.533,4 9.5,4 Z M10,11 L10,12 L9,12 L9,11 L10,11 Z M10,7 L10,10 L9,10 L9,7 L10,7 Z"
                          ></path>
                        </svg>
                        <span className="error">
                          {formErrors.cardHolderName}
                        </span>
                      </>
                    )}
                  </div>
                </div>

                <div className="card_input">
                  <label>メールアドレス</label>
                  <div
                    className="card_input_imgs"
                    style={{
                      border: formErrors.email
                        ? "1px solid rgb(223, 49, 49)"
                        : "",
                    }}
                  >
                    <input
                      id="email"
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                      required
                      placeholder="例えばteam@goagoa.click"
                      aria-required="true"
                      data-hook="field-input"
                      name="email"
                      type="email"
                      className="sbyRsif1 smMgyln bF0weP b7khad has-custom-focus"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="fill_div">
                    {formErrors.email && (
                      <>
                        <svg
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          width="20"
                          height="20"
                          class="sHcGI3k"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M9.5,3 C13.084,3 16,5.916 16,9.5 C16,13.084 13.084,16 9.5,16 C5.916,16 3,13.084 3,9.5 C3,5.916 5.916,3 9.5,3 Z M9.5,4 C6.467,4 4,6.467 4,9.5 C4,12.533 6.467,15 9.5,15 C12.533,15 15,12.533 15,9.5 C15,6.467 12.533,4 9.5,4 Z M10,11 L10,12 L9,12 L9,11 L10,11 Z M10,7 L10,10 L9,10 L9,7 L10,7 Z"
                          ></path>
                        </svg>
                        <span className="error">{formErrors.email}</span>
                      </>
                    )}
                  </div>
                </div>

                <div className="checkout_submit">
                  <button onClick={handlePlaceOrder}>購入する</button>
                </div>
              </div>
            </div>
            <div className="checkout_right">
              <div className="sWGbWl5">
                <h2>注文内容</h2>
                <div className="s__0pgkRb oNTTyHA--noWrap">
                  <span
                    className="sJdQmkp oMI3Bvi---typography-11-runningText oMI3Bvi---priority-7-primary sqV55CI"
                    aria-hidden="false"
                    data-hook="payment-checkout-summary-plan-name"
                    style={{ lineHeight: "1.5" }}
                  >
                    {product.name}
                  </span>
                  <span
                    className="sJdQmkp oMI3Bvi---typography-11-runningText oMI3Bvi---priority-7-primary s__1_MTxZ"
                    aria-hidden="false"
                    style={{ fontWeight: "100" }}
                  >
                    ￥{product.price}
                  </span>
                </div>
                <span
                  className="prioity_time"
                  aria-hidden="false"
                  data-hook="payment-checkout-summary-plan-duration"
                >
                  期間: キャンセルまで
                </span>
                <hr data-hook="title-divider" className="good_hr" />
                <div className="sjduORa">
                  <div
                    className="s__0pgkRb"
                    data-hook="payment-checkout-summary-subtotal-row"
                  >
                    <span
                      className=" sJdQmkp oMI3Bvi---typography-11-runningText oMI3Bvi---priority-7-primary s__1fcrXG"
                      aria-hidden="false"
                      style={{ fontWeight: "100", color: "#333" }}
                    >
                      小計
                    </span>
                    <span
                      className="sJdQmkp oMI3Bvi---typography-11-runningText oMI3Bvi---priority-7-primary s__1_MTxZ"
                      aria-hidden="false"
                      data-hook="payment-checkout-summary-subtotal"
                      style={{ fontWeight: "100", color: "#333" }}
                    >
                      ￥{product.price - product.price * 0.1}
                    </span>
                  </div>
                  <div
                    className="s__0pgkRb"
                    data-hook="payment-checkout-summary-tax-row"
                  >
                    <span
                      className="sJdQmkp oMI3Bvi---typography-11-runningText oMI3Bvi---priority-7-primary s__1fcrXG"
                      aria-hidden="false"
                      data-hook="payment-checkout-summary-tax-label"
                      style={{ fontWeight: "100", color: "#333" }}
                    >
                      <font _mstmutation="1">消費税 (10</font> %)
                    </span>
                    <span
                      className="sJdQmkp oMI3Bvi---typography-11-runningText oMI3Bvi---priority-7-primary s__1_MTxZ"
                      aria-hidden="false"
                      data-hook="payment-checkout-summary-tax"
                      style={{ fontWeight: "100", color: "#333" }}
                    >
                      ￥{product.price * 0.1}
                    </span>
                  </div>
                </div>
                <hr data-hook="title-divider" className="good_hr" />
                <div className="sjduORa">
                  <div className="s__0pgkRb">
                    <span
                      className="sJdQmkp oMI3Bvi---typography-11-runningText oMI3Bvi---priority-7-primary seZPWPf"
                      aria-hidden="false"
                      data-hook="payment-checkout-summary-plan-total"
                      style={{ fontSize: "18px" }}
                    >
                      合計
                    </span>
                    <span
                      className="sJdQmkp oMI3Bvi---typography-11-runningText oMI3Bvi---priority-7-primary slBhzna"
                      aria-hidden="false"
                      data-hook="payment-checkout-summary-plan-price"
                      style={{ fontSize: "18px" }}
                    >
                      ￥{product.price}
                    </span>
                  </div>
                </div>
              </div>
              <div className="shHkHG7" data-hook="secure-checkout-badge">
                <svg
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  width="24"
                  height="24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M9,7 C9,5.346 10.346,4 12,4 C13.654,4 15,5.346 15,7 L15,10 L9,10 L9,7 Z M17.5,10 L16,10 L16,7 C16,4.794 14.206,3 12,3 C9.794,3 8,4.794 8,7 L8,10 L6.5,10 C5.673,10 5,10.673 5,11.5 L5,19.5 C5,20.327 5.673,21 6.5,21 L17.5,21 C18.327,21 19,20.327 19,19.5 L19,11.5 C19,10.673 18.327,10 17.5,10 L17.5,10 Z"
                  ></path>
                </svg>{" "}
                <span
                  className="lock_div sJdQmkp oMI3Bvi---typography-11-runningText oMI3Bvi---priority-7-primary sOXZmvS"
                  aria-hidden="false"
                >
                  安全なお支払い
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CheckOut;
