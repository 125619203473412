import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/about.css";
import { Link } from "react-router-dom";

function About() {
  return (
    <div className="main-container">
      <Header />
      <div className="privacy_div_all">
        <div className="pf-about-us--banner-bgc">
          <div
            id=""
            className="pf-layout-container_privacy pf-layout-container--mobile"
          >
            <h1 className="pf-about-us--banner-title">About Us</h1>
            <div className="pf-about-us--banner-desc-container">
              <div className="pf-about-us--banner-desc">
                <div>
                  <img
                    alt=""
                    src="https://d3ss46vukfdtpo.cloudfront.net/static/media/img_perfectlogo.6cdde14c.png"
                    width="147"
                    style={{ position: "relative", top: "-12px" }}
                  />
                  (NYSE: PERF) is the leading SaaS artificial intelligence and
                  augmented reality beauty and fashion tech business solutions
                  provider, dedicated to transforming the consumer shopping
                  experience through seamless and omnichannel experiences. We
                  are pushing the frontiers of technology to offer
                  results-driven, inclusive, interactive, and sustainable
                  solutions that can be easily integrated into a brand’s website
                  and apps, as well as various social media channels, including
                  Instagram, Snapchat, YouTube, and many more.
                  <br />
                  <br />
                  On the consumer side, with over 1 billion downloads globally,
                  the immersive collection of YouCam apps provide a platform for
                  individuals to virtually try-on products, looks, and styles,
                  conveniently from their smart device.
                  <br />
                  <br />
                  <Link
                    className="pf-arrow-link--block"
                    to="/business/contact-us/sales"
                  >
                    <div>Contact Us</div>
                    <img
                      alt=""
                      src="https://d3ss46vukfdtpo.cloudfront.net/static/media/arrow.aa50316d.svg"
                    />
                  </Link>
                </div>
              </div>
              <div className="pf-about-us--banner-cover-container">
                <div className="pf-about-us--floating-card-container">
                  <div className="pf-about-us--floating-card-desc">
                    “We pioneer sustainable, high-touch, AI and AR-powered
                    omnichannel beauty and fashion tech solutions that empower
                    brands to enrich their relationships with customers.”
                  </div>
                  <div className="pf-about-us--floating-card-name">
                    CEO and Founder, Perfect Corp. <strong>Alice Chang</strong>
                  </div>
                </div>
                <img
                  alt=""
                  src="https://d3ss46vukfdtpo.cloudfront.net/static/media/img_AboutUs.9014c692.png"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="pf-about-us--sec1-bgc">
          <div className="pf-about-us--sec1-item-container">
            <div className="pf-about-us--sec1-item-title">
              40<span>+</span>
            </div>
            <div className="pf-about-us--sec1-item-desc">INDUSTRY AWARDS</div>
          </div>
          <div className="pf-about-us--sec1-item-container">
            <div className="pf-about-us--sec1-item-title">
              1B<span>+</span>
            </div>
            <div className="pf-about-us--sec1-item-desc">
              APP DOWNLOADS GLOBALLY
            </div>
          </div>
          <div className="pf-about-us--sec1-item-container">
            <div className="pf-about-us--sec1-item-title">
              400<span>+</span>
            </div>
            <div className="pf-about-us--sec1-item-desc">
              GLOBAL BRAND PARTERS
            </div>
          </div>
        </div>
        <div
          className="pf-content-text-image4"
          style={{
            backgroundColor: "rgb(255, 255, 255)",
            backgroundImage: "initial",
          }}
        >
          <div
            id=""
            className="pf-layout-container pf-layout-container--mobile pf-content-text-image4--container pf-content-text-image4--container-reverse"
          >
            <div className="pf-content-text-image4__text">
              <h2 className="pf-content-text-image4__text-title">
                <div className="pf-about-us--content_image-title">
                  Our Mission
                </div>
              </h2>
              <div className="pf-content-text-image4__text-desc">
                <div className="pf-about-us--content_image-desc">
                  <p>
                    Democratizing access to enriching digital shopping
                    experiences with AI &amp; AR-powered solutions.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="pf-content-text-image4__image undefined"
              title="img1"
            >
              <div className="undefined"></div>
              <img
                alt=""
                src="https://plugins-media.makeupar.com/smb/story/2022-02-10/28276e7a-228c-441a-a963-57f926fbb254.jpg"
              />
            </div>
          </div>
        </div>
        <div
          className="pf-content-text-image4"
          style={{
            backgroundColor: "rgb(255, 255, 255)",
            backgroundImage: "initial",
          }}
        >
          <div
            id=""
            className="pf-layout-container pf-layout-container--mobile pf-content-text-image4--container pf-content-text-image4--container-reverse"
          >
            <div
              className="pf-content-text-image4__image undefined"
              title="img1"
            >
              <div className="undefined"></div>
              <img
                alt=""
                src="https://plugins-media.makeupar.com/smb/story/2022-02-10/3aa4a75c-2f57-4234-8341-a93edf802a64.jpg"
              />
            </div>
            <div className="pf-content-text-image4__text">
              <h2 className="pf-content-text-image4__text-title">
                <div className="pf-about-us--content_image-title">
                  Our Vision
                </div>
              </h2>
              <div className="pf-content-text-image4__text-desc">
                <div className="pf-about-us--content_image-desc">
                  <p>
                    Transforming the world with AI and AR-powered tech
                    innovations..
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pf-about-us--sec4-bgc">
          <div
            id=""
            className="pf-layout-container pf-layout-container--mobile"
          >
            <div className="pf-about-us--sec4-title">
              Our Core Values and Principles
            </div>
            <div className="pf-about-us--sec4-card-container">
              <div className="pf-about-us--sec4-card">
                <img
                  alt=""
                  src="https://d3ss46vukfdtpo.cloudfront.net/static/media/icon_teamwork.c3c4a2b6.svg"
                />
                <div>
                  Innovation, strategic thinking and teamwork are at the heart
                  of everything we do.
                </div>
              </div>
              <div className="pf-about-us--sec4-card">
                <img
                  alt=""
                  src="https://d3ss46vukfdtpo.cloudfront.net/static/media/icon_excellence.28cc1fa8.svg"
                />
                <div>
                  We are committed to building trust, excellence and customer
                  satisfaction for all our partners.
                </div>
              </div>
              <div className="pf-about-us--sec4-card">
                <img
                  alt=""
                  src="https://d3ss46vukfdtpo.cloudfront.net/static/media/icon_dream.fa034a07.svg"
                />
                <div>
                  We encourage our employees to be bold, dream big, and embrace
                  change.
                </div>
              </div>
              <div className="pf-about-us--sec4-card">
                <img
                  alt=""
                  src="https://d3ss46vukfdtpo.cloudfront.net/static/media/icon_respect.aa93f5cb.svg"
                />
                <div>
                  We value diversity, integrity, and respect for others.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="pf-content-text-image4"
          style={{
            backgroundColor: "rgb(255, 255, 255)",
            backgroundImage: "initial",
          }}
        >
          <div
            id=""
            className="pf-layout-container pf-layout-container--mobile pf-content-text-image4--container pf-content-text-image4--container-reverse"
          >
            <div className="pf-content-text-image4__text">
              <h2 className="pf-content-text-image4__text-title">
                <div className="pf-about-us--content_image-title2">
                  Environmental, Social, and Governance
                </div>
              </h2>
              <div className="pf-content-text-image4__text-desc">
                Perfect Corp. is dedicated to building inclusive, eco-friendly,
                and frictionless cutting-edge solutions for the digital-first
                world by combining ethical management, inclusive workplaces,
                social resposibility and environmental protection.
              </div>
            </div>
            <div
              className="pf-content-text-image4__image undefined"
              title="img1"
            >
              <div className="undefined"></div>
              <img
                alt=""
                src="https://plugins-media.makeupar.com/smb/story/2022-02-10/0395fecc-7924-40b3-9476-d5390307f802.jpg"
              />
            </div>
          </div>
        </div>

        <div id="" className="pf-layout-container pf-about-us--sec6-bgc">
          <div className="pf-about-us--sec6-item">
            <div className="pf-about-us--sec6-title">
              <img
                alt=""
                src="https://d3ss46vukfdtpo.cloudfront.net/static/media/icon_awards.715b937d.svg"
              />
              <div>Awards</div>
            </div>
            <div>
              <div className="pf-about-us--sec6-desc">
                Learn about our outstanding achievements recognized by a number
                of reputable organizations worldwide.
              </div>
              <Link className="pf-arrow-link--block" to="/">
                <div>View all awards</div>
                <img
                  alt=""
                  src="https://d3ss46vukfdtpo.cloudfront.net/static/media/arrow.aa50316d.svg"
                />
              </Link>
            </div>
          </div>
          <div className="pf-about-us--sec6-item">
            <div className="pf-about-us--sec6-title">
              <img
                alt=""
                src="https://d3ss46vukfdtpo.cloudfront.net/static/media/icon_news.41392b7a.svg"
              />
              <div>News</div>
            </div>
            <div>
              <div className="pf-about-us--sec6-desc">
                Read about our latest product releases, brand partnerships,
                events, awards, appearances and more.
              </div>
              <Link className="pf-arrow-link--block" to="/">
                <div>See all news</div>
                <img
                  alt=""
                  src="https://d3ss46vukfdtpo.cloudfront.net/static/media/arrow.aa50316d.svg"
                />
              </Link>
            </div>
          </div>
          <div className="pf-about-us--sec6-item">
            <div className="pf-about-us--sec6-title">
              <img
                alt=""
                src="https://d3ss46vukfdtpo.cloudfront.net/static/media/icon_contact.59f07741.svg"
              />
              <div>Contact Us</div>
            </div>
            <div>
              <div className="pf-about-us--sec6-desc">
                Reach out to our brand success team to discover how our AI &amp;
                AR-powered solutions can transform your business.
              </div>
              <Link className="pf-arrow-link--block" to="/">
                <div>Speak to our team</div>
                <img
                  alt=""
                  src="https://d3ss46vukfdtpo.cloudfront.net/static/media/arrow.aa50316d.svg"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
