import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/main.css";
import { Link } from "react-router-dom";

function Main() {
  const handleNavigate = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="transparent-div">
          <div className="block-mv04_txt">
            <div className="ai_absolute">
              <p className="main1_top">​いま使われている！</p>
              <p className="main1_top">
                LINE登録者数<strong>１１１６</strong>件！！
              </p>
              <p className="main1_top">
                業界NO,1のオンラインインテリアサービス
              </p>
              <p className="main1_time">
                ※2024年8月時点：弊社LINE公式アカウント友だち登録者数による実績
              </p>
              <p className="main1_white">
                １部屋￥3,900～
                <br />
                気軽にプロとインテリアコーディネート
                <br />
                オンラインでかなうお部屋づくり
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="pink_box_div">
        <div className="pink_first_div">
          <div className="pink_first_div_inner">
            <img
              alt=""
              src="https://static.wixstatic.com/media/32eba4_86a1d8d418f24c14aa71adc2081dcc1e~mv2.png/v1/fit/w_276,h_232,q_90/32eba4_86a1d8d418f24c14aa71adc2081dcc1e~mv2.webp"
            />
          </div>
          <div className="pink_first_div_inner">
            <img
              alt=""
              src="https://static.wixstatic.com/media/32eba4_1cf77d1ad7a8462697d124fbc7b2e255~mv2.png/v1/fit/w_276,h_232,q_90/32eba4_1cf77d1ad7a8462697d124fbc7b2e255~mv2.webp"
            />
          </div>
          <div className="pink_first_div_inner">
            <img
              alt=""
              src="https://static.wixstatic.com/media/32eba4_2b9784d66a9a4e91a85f02725e47c577~mv2.png/v1/fit/w_276,h_232,q_90/32eba4_2b9784d66a9a4e91a85f02725e47c577~mv2.webp"
            />
          </div>
        </div>
        <div className="pink_bot_div">
          <img alt="" src="/head/one.png" width="866" />
        </div>
      </div>

      <div className="white_box_div">
        <h2>SHOWROOMINGってどんなサービス？</h2>
        <h3>
          いつでも気軽にプロへインテリアコーディネートを依頼できる
          <br />
          ​どこでもオンラインでお部屋づくりが楽しめる
          <br />
          ​インテリアコーディネートのワクワクをお届けするサービスです
        </h3>
        <ol>
          <li>
            誰でもオンラインでプロのインテリアコーディネーターに依頼できる
          </li>
          <li>スマホ・PCからいつでもどこでも手軽に利用できる</li>
          <li>
            ご依頼は１部屋￥3,900～（税込）、低価格で初めての方でも気軽に使える
          </li>
          <li>予算と目的に合わせた２つのコーディネートメニューから選べる</li>
        </ol>
      </div>

      <div className="thin_yellow_div">
        <h2>どんな時、オンラインインテリアコーディネートって使われる？</h2>
        <h3>
          例えばこんなシーンでお部屋づくりを考えている方に多く利用されています
        </h3>
        <div className="thin_yellow_flex">
          <div className="thin_yellow_flex_inner">
            <div style={{ textAlign: "center" }}>
              <img
                alt=""
                src="https://static.wixstatic.com/media/11062b_ade4242d728e4f19b499630680799a0c~mv2.jpeg/v1/crop/x_1685,y_0,w_5294,h_5304/fill/w_150,h_150,al_c,q_80,usm_1.20_1.00_0.01,enc_auto/11062b_ade4242d728e4f19b499630680799a0c~mv2.jpeg"
              />
            </div>
            <h3>お引越し</h3>
            <p>
              これまでの住まいが変化するときに新しいお部屋で快適な暮らしを送ることはインテリアだけでなく心も豊かにすることができます。
              <br />
              お引越しのタイミングでインテリアについて考える方は非常に多くいらっしゃります。お引越しでインテリアコーディネートを利用するお客様は、賃貸物件や新築物件のワンルーム・１K・１LDK・２LDK・３LDKの間取りで多く利用しています。
            </p>
          </div>
          <div className="thin_yellow_flex_inner">
            <div style={{ textAlign: "center" }}>
              <img
                alt=""
                src="https://static.wixstatic.com/media/11062b_cc8ed3dda2204a6fb89556fa0691e963~mv2.jpeg/v1/crop/x_872,y_0,w_3485,h_3485/fill/w_150,h_150,al_c,q_80,usm_1.20_1.00_0.01,enc_auto/%E3%81%AB%E7%A7%BB%E5%8B%95.jpeg"
              />
            </div>
            <h3>ご新築</h3>
            <p>
              戸建て・マンションを購入されたお客様は、これから長く住まう住居の間取りを決定した後にインテリアについて考えるタイミングがあります。{" "}
              <br />
              ご新築のお客様がインテリアコーディネートを依頼いただく際には内装（床・壁・ドア・キッチン）のカラーセレクトと照明計画、それに伴う家具のコーディネートを利用いただきます。{" "}
            </p>
          </div>
          <div className="thin_yellow_flex_inner">
            <div style={{ textAlign: "center" }}>
              <img
                alt=""
                src="https://static.wixstatic.com/media/11062b_e1633451520b4439a0b63706f829e8a1~mv2.jpg/v1/crop/x_834,y_0,w_3333,h_3333/fill/w_150,h_150,al_c,q_80,usm_1.20_1.00_0.01,enc_auto/%E5%AE%B6%E6%97%8F%E3%81%AE%E6%99%82%E9%96%93.jpg"
              />
            </div>
            <h3>​模様替え</h3>
            <p>
              今お住まいのお部屋づくりを見直すきっかけとしてインテリアコーディネートを利用されるお客様が多くいらっしゃります。{" "}
              <br />
              子供部屋づくりやリモートワーク部屋など、ライフスタイルの変化に伴いお部屋の用途が変わる場合や現在のレイアウトがしっくりこない、新しく購入する家具をどう取り入れるか、収納を増やしたいなど理想のお部屋づくりを実現するきっかけとして利用いただいております。{" "}
            </p>
          </div>
        </div>
      </div>

      <div className="white_box_div">
        <h2>オンラインの​インテリアコーディネーターに依頼、どんな理由？</h2>
        <h3 style={{ marginTop: "3%" }}>
          理想のお部屋で快適に暮らしたい、気軽にインテリアを楽しみたい
          <br />
          こんな理由で多くの方にご依頼いただいています
        </h3>
        <h4>
          実際にオンラインのインテリアコーディネートを利用したお客様の依頼理由はコチラ
        </h4>
        <p>＃今ある家具が新居に合うか心配​　</p>
        <p>＃床・ドア・壁紙と家具の相性が知りたい　</p>
        <p>＃家具のボリューム感やレイアウトがわからない</p>
        <p>＃新たなライフスタイルに適したインテリアでまとめたい</p>
        <p>#苦手な片付けが克服できる部屋づくりに挑戦したい　</p>
        <p>#カラーコーディネートで統一感のあるお部屋にしたい　</p>
        <p>＃ずっと手につかなかったインテリアづくりをはじめたい​</p>
      </div>

      <div className="thin_yellow_div">
        <h2>オンラインインテリアコーディネートのおススメポイントは？</h2>
        <h3>オンラインだから手軽に気軽にお部屋づくりが実現できます</h3>
        <div className="thin_yellow_flex">
          <div className="thin_yellow_flex_inner">
            <h3>会わないから安心できる</h3>
            <div style={{ textAlign: "center" }}>
              <img
                alt=""
                src="https://static.wixstatic.com/media/11062b_9e89cfa751c24103b6e8ed1c335bc836~mv2.jpg/v1/crop/x_834,y_0,w_3333,h_3333/fill/w_150,h_150,al_c,q_80,usm_1.20_1.00_0.01,enc_auto/%E5%9C%A8%E5%AE%85%E5%8B%A4%E5%8B%99.jpg"
              />
            </div>
            <p>・非対面だから遠慮なく意見を提示できる</p>
            <p>・自宅訪問が無いので気楽に依頼できる</p>
            <p>・小さな子供がいても衛生面で安心</p>
          </div>
          <div className="thin_yellow_flex_inner">
            <h3>全国どこでも対応できる</h3>
            <div style={{ textAlign: "center" }}>
              <img
                alt=""
                src="https://static.wixstatic.com/media/11062b_e28e6ea483da4d2d84fe1caaaedf2578~mv2.jpeg/v1/crop/x_983,y_0,w_3933,h_3933/fill/w_150,h_150,al_c,q_80,usm_1.20_1.00_0.01,enc_auto/%E7%A9%BA%E6%B8%AF%E3%82%92%E6%AD%A9%E3%81%8F%E6%97%85%E8%A1%8C%E8%80%85.jpeg"
              />
            </div>

            <p>・スマホ、PCがあればどこからでも使える </p>
            <p>・新居のお部屋が遠方でも大丈夫</p>
            <p>・全国一律で平等なサービスを提供出来る</p>
          </div>
          <div className="thin_yellow_flex_inner">
            <h3>出費を抑えられる</h3>
            <div style={{ textAlign: "center" }}>
              <img
                alt=""
                src="https://static.wixstatic.com/media/11062b_82018cea0f894d82b12d57b3a0ae0b70~mv2.jpg/v1/crop/x_834,y_0,w_3333,h_3333/fill/w_150,h_150,al_c,q_80,usm_1.20_1.00_0.01,enc_auto/%E3%82%B3%E3%82%A4%E3%83%B3%E3%82%B8%E3%83%A3%E3%83%BC.jpg"
              />
            </div>

            <p>・敷居が低く学生さんも使える依頼料金 </p>
            <p>・引越し代、家具購入費、住宅購入資金</p>
            <p>・を節約しながらお部屋づくりができる</p>
          </div>
        </div>
      </div>

      <div className="service_house_div">
        <h2>お部屋づくりの​コーディネートメニュー</h2>
        <h4>​一般個人のお客様限定</h4>
        <div className="house_two_box">
          <div className="houser_inner">
            <h3>オンラインコーディネート</h3>
            <h3>
              料金：１室<strong className="color_red">￥3,900</strong>(税込)
            </h3>
            <div style={{ textAlign: "center" }}>
              <img
                width="311"
                height="261"
                alt=""
                src="https://static.wixstatic.com/media/32eba4_92b652f227fe443eb196dd17458ca66f~mv2.png/v1/fill/w_311,h_261,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/32eba4_92b652f227fe443eb196dd17458ca66f~mv2.png"
                style={{ objectFit: "cover", objectPosition: "50% 50%" }}
              />
            </div>
            <p>・LINE専用トークルームでヒアリング</p>
            <p>・レイアウトプランボード、インテリアリスト作成</p>
            <p>・家具お探しサポート</p>
            <br />
            <br />
            <p style={{ textAlign: "center", textDecoration: "underline" }}>
              お部屋に対する不安や悩みを低価格で相談できます
            </p>
            <p style={{ textAlign: "center", lineHeight: "1.4" }}>
              インテリアリストには購入可能な商品URLを記載
              <br />
              実際の商品をオンラインですぐに確認・購入できます​
              <br />
              LINEトークルームではお取引終了後も家具探しをサポート
            </p>
          </div>

          <div className="houser_inner">
            <h3>３Dインテリアコーディネート</h3>
            <h3>
              料金：１室<strong className="color_red">￥8,900</strong>(税込)
            </h3>
            <div style={{ textAlign: "center" }}>
              <img
                width="311"
                height="261"
                alt=""
                src="https://static.wixstatic.com/media/32eba4_5eefe02f84c74574a54203612439718f~mv2.png/v1/fill/w_311,h_261,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/32eba4_5eefe02f84c74574a54203612439718f~mv2.png"
                style={{ objectFit: "cover", objectPosition: "50% 50%" }}
              />
            </div>
            <p>・LINE専用トークルームでヒアリング​</p>
            <p>・３Dインテリアイメージ図作成</p>
            <p>・レイアウトプランボード、インテリアリスト作成</p>
            <p>・家具お探しサポート</p>
            <br />
            <br />
            <p style={{ textAlign: "center", textDecoration: "underline" }}>
              ３Dでご自宅を再現するバーチャルプランでご提案
            </p>
            <p style={{ textAlign: "center", lineHeight: "1.4" }}>
              インテリアリストには購入可能な商品URLを記載
              <br />
              実際の商品をオンラインですぐに確認・購入できます​
              <br />
              LINEトークルームではお取引終了後も家具探しをサポート
            </p>
          </div>
        </div>
        <p className="particular_p">【　相談無料　】</p>
        <p style={{ textAlign: "center" }}>
          <img
            alt=""
            src="https://static.wixstatic.com/media/32eba4_db8db0b5870148d9bdf7a387862e16d8~mv2.png/v1/fill/w_244,h_91,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/ZOOM%2B%E3%81%AE%E3%82%B3%E3%83%94%E3%83%BC%E3%81%AE%E3%82%B3%E3%83%94%E3%83%BC2.png"
          />
        </p>
        <p style={{ textAlign: "center", margin: "3% 0" }}>
          <Link to="/service" className="service_buy" onClick={handleNavigate}>
            サービスの詳細・料金はコチラ
          </Link>
        </p>
      </div>

      <div className="thin_yellow_div">
        <h2>【NEW】レンタルスペースコーディネートパック</h2>
        <div className="house_two_box">
          <div className="houser_inner">
            <h3 className="money_strong">
              料金：１スペース<strong className="color_red">￥10,900</strong>
              (税込)
            </h3>
            <h3 className="money_normal">
              レンタルスペース・民泊コーディネート
            </h3>
            <div style={{ textAlign: "center" }}>
              <img
                width="311"
                height="261"
                alt=""
                src="https://static.wixstatic.com/media/32eba4_41e2c1a6851748b9b5bcec170297e9a8~mv2.png/v1/fill/w_320,h_268,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/32eba4_41e2c1a6851748b9b5bcec170297e9a8~mv2.png"
                style={{ objectFit: "cover", objectPosition: "50% 50%" }}
              />
            </div>
            <p>・LINE専用トークルームでヒアリング</p>
            <p>・レイアウトプランボード、インテリアリスト作成</p>
            <p>・家具お探しサポート</p>
            <br />
            <br />

            <p style={{ textAlign: "center", lineHeight: "1.4" }}>
              インテリアリストには購入可能な商品URLを記載
              <br />
              実際の商品をオンラインですぐに確認・購入できます​
              <br />
              LINEトークルームではお取引終了後も家具探しをサポート
            </p>
          </div>
        </div>
        <p className="particular_p">【　お問い合わせ・相談無料　】</p>
        <p style={{ textAlign: "center" }}>
          <img
            alt=""
            src="https://static.wixstatic.com/media/32eba4_db8db0b5870148d9bdf7a387862e16d8~mv2.png/v1/fill/w_244,h_91,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/ZOOM%2B%E3%81%AE%E3%82%B3%E3%83%94%E3%83%BC%E3%81%AE%E3%82%B3%E3%83%94%E3%83%BC2.png"
          />
        </p>
        <p style={{ textAlign: "center", margin: "3% 0" }}>
          <Link to="/service" className="service_buy">
            サービスの詳細・料金はコチラ
          </Link>
        </p>
      </div>

      <div className="new_show_div">
        <h2 className="new_h2">
          <img alt="" src="/head/new.png" />
          新着コーディネート
        </h2>
        <p>
          SHOWROOMINGのサービスを利用頂いたお客様の実例納品をInstagramに掲載しています
        </p>
        <p>サービスの提案内容やプランが気になる方はぜひご覧ください</p>
        <br />
        <div className="new_four_imgs">
          <img
            alt=""
            src="https://scontent-iad3-2.cdninstagram.com/v/t39.30808-6/460641364_1068170641861486_3939575028469086598_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=18de74&_nc_ohc=wJouDKC5TLMQ7kNvgEUBAV6&_nc_ht=scontent-iad3-2.cdninstagram.com&edm=AEQ6tj4EAAAA&_nc_gid=AmSkbfBS5JO-NhMS0v4bGON&oh=00_AYCZ0ca-4zFBFoFCu7X0Y2nAL7mbQW8_vCKbdrVXYixkog&oe=671E6AD0"
          />
          <img
            alt=""
            src="https://scontent-iad3-1.cdninstagram.com/v/t39.30808-6/460306635_1068168295195054_2597860282921854916_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=18de74&_nc_ohc=dZWFFs9uWuwQ7kNvgEFFpbk&_nc_ht=scontent-iad3-1.cdninstagram.com&edm=AEQ6tj4EAAAA&_nc_gid=AY7ajFb7Jev3Vv4eDqtQnJe&oh=00_AYB5wzXQ2AIzMiR5nwnruITaOt4GU1SbBH4t6kJhH1OC-A&oe=671E7B53"
          />
          <img
            alt=""
            src="https://scontent-iad3-2.cdninstagram.com/v/t39.30808-6/460588370_1068165148528702_4263503442462466083_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=18de74&_nc_ohc=BGW6ycqMfrQQ7kNvgEROHlw&_nc_ht=scontent-iad3-2.cdninstagram.com&edm=AEQ6tj4EAAAA&_nc_gid=AhpEbHy_UUK1OLSN5FDnDkg&oh=00_AYDupyni7hV0oKEvUZO3anGeyqPC7ltkmBwI4cnvy9ciwA&oe=671E5EA0"
          />
          <img
            alt=""
            src="https://scontent-iad3-2.cdninstagram.com/v/t39.30808-6/460450072_1068162135195670_7126536267977513330_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=18de74&_nc_ohc=PhiHa18k22cQ7kNvgH5O1Sy&_nc_ht=scontent-iad3-2.cdninstagram.com&edm=AEQ6tj4EAAAA&_nc_gid=AYcJCykmi9GHDzfcymt6KEx&oh=00_AYASOrC02-0aowkxANcEhNUkT2-4utlMU5yByTiUW1DZDA&oe=671E7DDA"
          />
        </div>
        <br /> <br />
        <h2 className="show_room">SHOWROOMINGが提供する４つのポイント</h2>
        <div className="point_img_txt">
          <div className="point_img">
            <p className="two_point">
              <span className="point_left">Point.</span>
              <span className="point_right">１</span>
            </p>
            <img
              alt=""
              src="https://static.wixstatic.com/media/21276e9bb2a04809a76f2a7bfe161219.jpg/v1/fill/w_167,h_167,al_c,q_80,usm_1.20_1.00_0.01,enc_auto/%E3%82%AA%E3%83%B3%E3%83%A9%E3%82%A4%E3%83%B3%E3%82%B7%E3%83%A7%E3%83%83%E3%83%94%E3%83%B3%E3%82%B0.jpg"
            />
          </div>
          <div className="point_txt">
            <h3>安心！リーズナブルプライス</h3>
            <p>オンラインだから実現できた料金設定</p>
            <p>コーディネート料は１部屋￥3900～</p>
            <p>学生・新社会人・主婦の方も手軽に利用できます</p>
          </div>
        </div>
        <div className="point_img_txt">
          <div className="point_img">
            <p className="two_point">
              <span className="point_left">Point.</span>
              <span className="point_right">2</span>
            </p>
            <img
              alt=""
              src="https://static.wixstatic.com/media/11062b_7c29d799d7e44126a47f826beb22df71~mv2.jpg/v1/fill/w_167,h_167,al_c,q_80,usm_1.20_1.00_0.01,enc_auto/%E3%82%B9%E3%83%9E%E3%83%9B%E3%82%92%E8%A6%8B%E3%82%8B%E5%A5%B3%E6%80%A7.jpg"
            />
          </div>
          <div className="point_txt">
            <h3>手軽！オンライン完結サービス</h3>
            <p>完全オンラインで完結するサービスだから</p>
            <p>スマホ・PCからラクラク利用できます</p>
            <p>すべてLINEで行うので面倒なユーザー登録などは不要です</p>
          </div>
        </div>
        <div className="point_img_txt">
          <div className="point_img">
            <p className="two_point">
              <span className="point_left">Point.</span>
              <span className="point_right">3</span>
            </p>
            <img
              alt=""
              src="https://static.wixstatic.com/media/11062b_0346f85f20f541ee84751c7b35c29448~mv2.jpg/v1/fill/w_167,h_167,al_c,q_80,usm_1.20_1.00_0.01,enc_auto/%E8%A6%AA%E5%AD%90%E3%81%A8%E3%82%BD%E3%83%BC%E3%82%B7%E3%83%A3%E3%83%AB%E3%83%AF%E3%83%BC%E3%82%AB%E3%83%BC.jpg"
            />
          </div>
          <div className="point_txt">
            <h3>感動！一目瞭然のインテリア提案</h3>
            <p>お届けするコーディネート内容は</p>
            <p>インテリアプランボードとしてデータ納品します</p>
            <p>レイアウト図や3Dイメージ、ワンポイントアドバイスや商品リスト</p>
            <p>これらをひとつにまとめた一目瞭然の提案品</p>
          </div>
        </div>
        <div className="point_img_txt">
          <div className="point_img">
            <p className="two_point">
              <span className="point_left">Point.</span>
              <span className="point_right">4</span>
            </p>
            <img
              alt=""
              src="https://static.wixstatic.com/media/ab4a37030b8243a49a9de4fe80ca7fa6.jpg/v1/fill/w_167,h_167,al_c,q_80,usm_1.20_1.00_0.01,enc_auto/%E3%83%9F%E3%82%B9%E3%83%88%E3%82%92%E8%A6%8B%E6%B8%A1%E3%81%97%E3%81%BE%E3%81%99.jpg"
            />
          </div>
          <div className="point_txt">
            <h3>納得！インテリア業界プロのスキル</h3>
            <p>お客様を迎えるプランナーはインテリア業界に従事する</p>
            <p>プロコーディネーター、体感する敷居が高いスキルを</p>
            <p>圧倒的なコスパでご提供いたします</p>
          </div>
        </div>
      </div>

      <div className="thin_yellow_div step_box">
        <h2>
          オンラインインテリアコーディネート
          <br />
          サービスご利用の流れ
        </h2>
        <h3>
          STEP１
          <br />
          LINE登録（無料）をする
        </h3>
        <h3 style={{ marginBottom: "15px" }}>
          STEP２
          <br />
          プラン内容と料金を確認する
        </h3>
        <p>プランコースと作成料金を事前にご提示いたします</p>
        <h3 style={{ marginBottom: "15px" }}>
          STEP３
          <br />
          ご決済
        </h3>
        <p>
          決済方法は各種クレジット（JCB VISA Master
          AMEX)・ApplePay・Alipay・銀行振込をご用意しています
          <br />
          ※SHOWROOMINGはSDGｓ貢献への取り組みとして、ご購入金額の1%を大気中からCO2を除去するために寄付しています
        </p>

        <h3 style={{ marginBottom: "15px" }}>
          STEP４
          <br />
          コーディネート内容ヒアリング
        </h3>
        <p>送付するヒアリングシートをもとにプランニングをスタートいたします</p>

        <h3 style={{ marginBottom: "15px" }}>
          STEP5
          <br />
          プランニング開始
        </h3>
        <p>
          ３Dコーディネート又はオンラインコーディネートのプラン作成を開始します
          <br />
          ご自宅と提案家具が再現された３Dイメージや実際に購入可能なURL付アイテムリストプランボードを納品いたします
        </p>
      </div>

      <div className="team_show">
        <h2>
          TEAM SHOWROOMING
          <br />
          プロジェクトスタッフ
        </h2>
        <p>
          SHOWROOMINGのオンラインインテリアコーディネートをプランするスタッフはコチラ
          <br />
          あなたのコーディネートをチーム一丸となってプランニングしています
        </p>
        <h2 className="team_h2_sec">オンラインインテリアプランナー</h2>
        <div className="thin_yellow_flex">
          <div className="thin_yellow_flex_inner">
            <h3>funa</h3>
            <div style={{ textAlign: "center", marginBottom: "15%" }}>
              <img alt="" src="/head/person1.jpg" />
            </div>
            <p className="person_normal_p">【資格】</p>
            <p className="person_normal_p">インテリアコーディネーター</p>
            <p className="person_normal_p">カラーコーディネーター</p>
            <p className="person_normal_p">福祉住環境コーディネーター</p>
            <p className="person_normal_p">【経歴】</p>
            <p className="person_normal_p">大手家具販売店アドバイザー</p>
            <p className="person_normal_p">
              某スキルマーケットインテリアトップセラー
            </p>
            <p className="person_normal_p">【得意ジャンル】</p>
            <p className="person_normal_p">
              ファミリー・一人暮らし・リモートワーク・レンタルスペース・オフィス・店舗
            </p>
            <p className="person_normal_p">【好きなインテリアスタイル】</p>
            <p className="person_normal_p">
              北欧・ヴィンテージ・モダン・アジアン
            </p>
          </div>
          <div className="thin_yellow_flex_inner">
            <h3>全国どこでも対応できる</h3>
            <div style={{ textAlign: "center", marginBottom: "15%" }}>
              <img alt="" src="/head/person3.jpg" />
            </div>

            <p className="person_normal_p">【資格】</p>
            <p className="person_normal_p">インテリアコーディネーター</p>
            <p className="person_normal_p">カラーコーディネーター</p>
            <p className="person_normal_p">整理収納アドバイザー</p>
            <p className="person_normal_p">【経歴】</p>
            <p className="person_normal_p">
              不動産会社の新築、リフォームインテリアコーディネーターとして数百件のお客様を接客
            </p>
            <p className="person_normal_p">【得意ジャンル】</p>
            <p className="person_normal_p">
              キッズ・ファミリー・主婦・新築戸建て・新築マンション・リフォーム
            </p>
            <p className="person_normal_p">【好きなインテリアスタイル】</p>
            <p className="person_normal_p">
              ナチュラル・北欧・ホテルライク・ベージュ
            </p>
          </div>
          <div className="thin_yellow_flex_inner">
            <h3>出費を抑えられる</h3>
            <div style={{ textAlign: "center", marginBottom: "15%" }}>
              <img alt="" src="/head/person2.jpg" />
            </div>
            <p className="person_normal_p">【資格】</p>
            <p className="person_normal_p">インテリアコーディネーター</p>
            <p className="person_normal_p">カラーコーディネーター</p>
            <p className="person_normal_p"></p>
            <p className="person_normal_p">【経歴】</p>
            <p className="person_normal_p">
              フリーランスのインテリアコーディネーター業界経験１０年のベテラン
            </p>
            <p className="person_normal_p">【得意ジャンル】</p>
            <p className="person_normal_p">
              女子部屋・ペット・寝室・ワンルーム・マンション
            </p>
            <p className="person_normal_p">【好きなインテリアスタイル】</p>
            <p className="person_normal_p">
              エレガント・ホテルライク・ホワイト
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Main;
