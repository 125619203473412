import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/privacy.css";

function Particular() {
  return (
    <div className="main-container">
      <Header />
      <div className="privacy_box">
        <h2>特定商取引法に基づく表示</h2>
        <div className="pf-privacy">
          <div className="pf-layout-container">
            <div className="pf-privacy-content">
              <h3>販売価格</h3>
              <p>
                コーディネートサービスプランごと、また販売商品ごとに表示致します。{" "}
              </p>
              <h3>代金のお支払い時期</h3>
              <p>サービス依頼都度に個別トークルーム内で請求します。 </p>
              <h3>役務または商品の引渡し時期</h3>
              <p>
                ヒアリング終了後不測の事態が無い場合に最長12営業日で提供します。
                <br />
                料金支払い後、プランニング手続きに取り掛かります。
              </p>
              <h3>返品・返金について</h3>
              <p>
                原則としてご決済いただいた商品は、『 プランニング開始後 』
                にはキャンセルを承る事ができません。
              </p>
              <h3>事業者・連絡先</h3>
              <p>
                名称: TANYA UUOO LLC
                <br />
                住所: 〒1309 Coffeen Avenue STE 1200，Sheridan, Wyoming 82801
                <br />
                メール:team@goagoa.click
                <br />
                代表: 舟久保宏輔
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Particular;
