import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/service.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import productData from "../Datas/productData";

function Service() {
  const handleNavigate = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="main-container">
      <Header />
      <div className="service_box_div">
        <div className="service_box_div_inner">
          <div className="service_first_box">
            <h1>
              <Link to="/" onClick={handleNavigate}>
                HOME
              </Link>
              <p>​＞</p>
              <p>サービス詳細</p>
            </h1>
          </div>
          <div className="service_sec_box">
            <p>サービス詳細</p>
          </div>
          <div className="service_comp">
            <div className="service_comp_inner">
              <p className="inner_pink_p">​オンラインコーディネート</p>
              <img
                alt=""
                src="https://static.wixstatic.com/media/32eba4_1cf77d1ad7a8462697d124fbc7b2e255~mv2.png/v1/fill/w_449,h_369,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/1.png"
              />
              <p>
                オンラインコーディネートではお客様から頂いた資料とご要望からコーディネーターが
              </p>
              <p>
                <strong>
                  レイアウトプラン・コーディネートポイント・アイテムリスト
                </strong>
                をプランニングしお届けいたします。
              </p>
              <p>
                お部屋に対する不安や悩みが手軽な価格で相談できるサービスとして人気のサービスです。
              </p>
              <p>アイテムリストには商品掲載URLをリンクしており</p>
              <p>ます。</p>
              <p>
                実際の商品をオンラインですぐに確認・購入できるようになります。
              </p>
              <img
                alt=""
                src="https://static.wixstatic.com/media/32eba4_aa6b0f3f998e4d6aaccdfd99466e51c7~mv2.jpg/v1/fill/w_369,h_262,al_c,q_80,usm_1.20_1.00_0.01,enc_auto/2_optimized.jpg"
              />
              <p style={{ display: "flex", justifyContent: "center" }}>
                <img
                  alt=""
                  src="https://static.wixstatic.com/media/32eba4_aa6b0f3f998e4d6aaccdfd99466e51c7~mv2.jpg/v1/fit/w_159,h_112,q_90/32eba4_aa6b0f3f998e4d6aaccdfd99466e51c7~mv2.webp"
                />
                <img
                  alt=""
                  src="https://static.wixstatic.com/media/32eba4_a1729db33832470d85a7de3be3f6cb4b~mv2.jpg/v1/fit/w_159,h_112,q_90/32eba4_a1729db33832470d85a7de3be3f6cb4b~mv2.webp"
                />
              </p>
              <p className="inner_blue_p">​レンタルスペースデザイン</p>
              <img
                alt=""
                src="https://static.wixstatic.com/media/32eba4_2b9784d66a9a4e91a85f02725e47c577~mv2.png/v1/fill/w_449,h_369,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/3.png"
              />
            </div>
            <div className="service_comp_inner">
              <p className="inner_pink_p">3Dインテリアコーディネート</p>
              <img
                alt=""
                style={{ marginBottom: "22.5%" }}
                src="https://static.wixstatic.com/media/32eba4_e992c2b40ca04a2ab0b7103c91688223~mv2.png/v1/fill/w_449,h_369,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/2.png"
              />
              <p>
                ３Dインテリアコーディネートサービスではお客様から頂いた資料とご要望から
                <strong>
                  あなたのお部屋を３Dで再現し、３Dで再現されたアイテムリストのご提案家具をレイアウトしプランニング
                </strong>
                いたします。
              </p>
              <p>
                想像ではカバーできないインテリアのイメージを具体的に表現することで目でみてわかるコーディネートプランをお届けいたします。
              </p>
              <p>
                一度作成したお客様のデータはサーバーに保管してあるのでライフスタイルの変化でリピート利用の際にはお手軽に利用することができます。
              </p>
              <img
                alt=""
                src="https://static.wixstatic.com/media/32eba4_aa0f4a09d44b422592013e2daae5f4ff~mv2.png/v1/fill/w_369,h_262,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/6_optimized.png"
              />
              <p style={{ display: "flex", justifyContent: "center" }}>
                <img
                  alt=""
                  src="https://static.wixstatic.com/media/32eba4_197a212d9ce3464cb6ce03bcadfad908~mv2.jpg/v1/fit/w_157,h_111,q_90/32eba4_197a212d9ce3464cb6ce03bcadfad908~mv2.webp"
                />
                <img
                  alt=""
                  src="https://static.wixstatic.com/media/32eba4_e6c933f3242d43c4bc510e846d926a81~mv2.png/v1/fit/w_111,h_111,q_90/32eba4_e6c933f3242d43c4bc510e846d926a81~mv2.webp"
                />
              </p>
              <p style={{ marginBottom: "15%" }}></p>
              <img
                alt=""
                src="https://static.wixstatic.com/media/32eba4_c72b2e721de94f90b3221de57ced5831~mv2.jpg/v1/fill/w_378,h_264,q_90/32eba4_c72b2e721de94f90b3221de57ced5831~mv2.webp"
              />
            </div>
          </div>
          <p className="text_text">
            ​レンタルスペースデザイン
            ​レンタルスペースコーディネートではスペースの図面・間取り図資料と室内写真から用途に合わせた
            <strong>
              ３Dスペースデザインと家具・備品のセレクトコーディネート
            </strong>
            をプランし提案します。
          </p>
          <p className="text_text">
            セルフプロデュースでは掴みづらい完成イメージやデザインプランの立ち上げをお任せいただく事でスムーズなスペースオープンに役立つサービスとなります
          </p>
          <div className="service_steps">
            <h2>手順は簡単 6 STEP！</h2>
            <div className="steps_grid">
              <div className="steps_grid_inner">
                <div className="step_top">
                  <h3>
                    STEP
                    <span>01</span>
                  </h3>
                </div>
                <div className="step_mid">
                  <img
                    alt=""
                    src="https://static.wixstatic.com/media/32eba4_44df6339a60a43dd99e7360df01a71d0~mv2.png/v1/fill/w_100,h_100,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/LINE_APP_edited.png"
                  />
                  <h4>LINE会員登録</h4>
                </div>
                <div id="comp-l95nctpe3__item1" className="comp-line"></div>
                <p>
                  『 LINE会員登録
                  』完了でトークルーム開設され、担当者とのやり取りが始まります。
                </p>
              </div>
              <div className="steps_grid_inner">
                <div className="step_top">
                  <h3>
                    STEP
                    <span>02</span>
                  </h3>
                </div>
                <div className="step_mid">
                  <img
                    alt=""
                    src="https://static.wixstatic.com/media/4071c1_6e49b2ac73b64e8087aeeff67d2498d0~mv2.png/v1/fill/w_100,h_100,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/%E3%83%8D%E3%83%83%E3%83%88%E6%8E%A5%E7%B6%9A%E3%82%A2%E3%82%A4%E3%82%B3%E3%83%B33.png"
                  />
                  <h4>見積・購入</h4>
                </div>
                <div id="comp-l95nctpe3__item1" className="comp-line"></div>
                <p>
                  トークルーム内にてお見積り
                  クレジットカード又は銀行振込　でのご決済を対応致します。
                </p>
              </div>
              <div className="steps_grid_inner">
                <div className="step_top">
                  <h3>
                    STEP
                    <span>03</span>
                  </h3>
                </div>
                <div className="step_mid">
                  <img
                    alt=""
                    src="https://static.wixstatic.com/media/4071c1_9af65b71a3a747ccb96c302482cab6e5~mv2.png/v1/fill/w_100,h_100,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/%E3%82%A6%E3%82%A3%E3%83%B3%E3%83%89%E3%82%A6%E3%82%A2%E3%82%A4%E3%82%B3%E3%83%B32.png"
                  />
                  <h4>サービス開始</h4>
                </div>
                <div id="comp-l95nctpe3__item1" className="comp-line"></div>
                <p>
                  お客様お手持ちの、間取り図・資料等データ、及びご要望内容をお送りください。
                </p>
              </div>
              <div className="steps_grid_inner">
                <div className="step_top">
                  <h3>
                    STEP
                    <span>04</span>
                  </h3>
                </div>
                <div className="step_mid">
                  <img
                    alt=""
                    src="https://static.wixstatic.com/media/4071c1_c89cfe963c74458984b02569a297fea7~mv2.png/v1/fill/w_100,h_100,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/%E3%83%81%E3%82%A7%E3%83%83%E3%82%AF%EF%BD%8B%E3%83%AA%E3%82%B9%E3%83%88.png"
                  />
                  <h4>ヒアリング</h4>
                </div>
                <div id="comp-l95nctpe3__item1" className="comp-line"></div>
                <p>
                  資料を基にトークルームでヒアリングを開始し、お客様の細かなご要望をお聞きします。
                </p>
              </div>
              <div className="steps_grid_inner">
                <div className="step_top">
                  <h3>
                    STEP
                    <span>05</span>
                  </h3>
                </div>
                <div className="step_mid">
                  <img
                    alt=""
                    src="https://static.wixstatic.com/media/4071c1_17eb8126ff15478fa7a8b401e6c8b715~mv2.png/v1/fill/w_100,h_100,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/%E5%AE%B6%E3%81%AE%E3%82%A2%E3%82%A4%E3%82%B3%E3%83%B3%E7%B4%A0%E6%9D%90%20(1).png"
                  />
                  <h4>プランニング</h4>
                </div>
                <div id="comp-l95nctpe3__item1" className="comp-line"></div>
                <p>
                  プロがじっくりと最適なプランを作り上げます。​制作期間はおおむね10日間程度
                </p>
              </div>
              <div className="steps_grid_inner">
                <div className="step_top">
                  <h3>
                    STEP
                    <span>06</span>
                  </h3>
                </div>
                <div className="step_mid">
                  <img
                    alt=""
                    src="https://static.wixstatic.com/media/4071c1_624ec75d560847689d0b55a7cc6c7b12~mv2.png/v1/fill/w_100,h_100,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/%E3%83%97%E3%83%AC%E3%82%BC%E3%83%B3%E3%83%86%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%B3%E3%82%A2%E3%82%A4%E3%82%B3%E3%83%B3.png"
                  />
                  <h4>ご提案</h4>
                </div>
                <div id="comp-l95nctpe3__item1" className="comp-line"></div>
                <p>
                  プランニングが終わりましたら、いよいよご提案です。3Dイメージで理想のお部屋をご覧ください。
                </p>
              </div>
            </div>
          </div>

          <div className="service_steps">
            <h2>ご提案に含まれるもの</h2>
            <p className="voice_p">
              プラン完成後、トークルーム内に下記成果品をお送りさせていただきます。
            </p>
            <p className="voice_p">
              成果品はすべてオンラインでの納品となります。
            </p>
          </div>

          <div className="service_repeat">
            <div className="repeat_top">3Dインテリア図</div>
            <div className="repeat_mid">
              <div className="repeat_mid_left">
                <img
                  alt=""
                  src="https://static.wixstatic.com/media/32eba4_e6c933f3242d43c4bc510e846d926a81~mv2.png/v1/fill/w_256,h_256,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/4_optimized.png"
                />
                <img
                  alt=""
                  src="https://static.wixstatic.com/media/32eba4_bec5ca0933e6490ba2fc83a2efe0ef58~mv2.png/v1/fill/w_238,h_238,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/7_optimized.png"
                />
                <img
                  alt=""
                  src="https://static.wixstatic.com/media/32eba4_aa0f4a09d44b422592013e2daae5f4ff~mv2.png/v1/fill/w_251,h_251,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/6_optimized.png"
                />
                <img
                  alt=""
                  src="https://static.wixstatic.com/media/32eba4_4ee0fb8507514c67b61aaaff2efeebee~mv2.png/v1/fill/w_243,h_243,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/5_optimized.png"
                />
              </div>

              <div className="repeat_mid_right">
                <p>
                  自宅室内を3Dで再現しインテリアコーディネートされたイメージを確認できる3Dインテリア図！
                  <br />
                  バーチャルならではの上からお部屋をみた図や室内さまざまな角度からコーディネートされたお部屋を確認することが出来ます
                </p>
                <p>※3Dインテリアコーディネートサービスのみとなります</p>
              </div>
            </div>
          </div>

          <div className="service_repeat">
            <div className="repeat_top">​インテリアプランボード</div>
            <div className="repeat_mid">
              <div className="repeat_mid_left">
                <img
                  alt=""
                  src="https://static.wixstatic.com/media/32eba4_aa6b0f3f998e4d6aaccdfd99466e51c7~mv2.jpg/v1/fill/w_330,h_233,al_c,q_80,usm_1.20_1.00_0.01,enc_auto/2_optimized.jpg"
                />
                <img
                  alt=""
                  src="https://static.wixstatic.com/media/32eba4_6ce98e4a1f4e4db185f1de7e5f68618e~mv2.png/v1/fill/w_238,h_238,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/8_optimized.png"
                />
                <img
                  alt=""
                  src="https://static.wixstatic.com/media/32eba4_2678d4378bba454d8631abb3822645bd~mv2.png/v1/fill/w_251,h_251,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/9_optimized.png"
                />
                <img
                  alt=""
                  src="https://static.wixstatic.com/media/32eba4_e3c84417c64a44e881794328ee6657fd~mv2.jpg/v1/fill/w_307,h_217,al_c,q_80,usm_1.20_1.00_0.01,enc_auto/3_optimized.jpg"
                />
              </div>

              <div className="repeat_mid_right">
                <p>
                  インテリアプランボードの内容は <br /> ①コーディネート詳細
                  <br />
                  →インテリアプラン・レイアウト説明
                  <br />
                  →レイアウト図
                  <br />
                  ②アイテムリスト
                  <br />
                  ​→商品URL・画像・サイズ等の情報入り
                </p>
              </div>
            </div>
          </div>

          <h3 className="material_h3">
            メニュー＆料金<span>※表示料金はすべて税込みです。</span>
          </h3>

          <div className="service_comp">
            <div className="service_comp_inner">
              <p className="inner_pink_p">オンラインコーディネート</p>
              <img
                alt=""
                src="https://static.wixstatic.com/media/32eba4_db1a1f3e09c74a1c974573a6b758e0ec~mv2.jpg/v1/fill/w_369,h_140,al_c,q_80,usm_1.20_1.00_0.01,enc_auto/%E3%82%B9%E3%82%AF%E3%83%AA%E3%83%BC%E3%83%B3%E3%82%B7%E3%83%A7%E3%83%83%E3%83%88%202023-10-28%20025251.jpg"
              />
              <p>
                <span
                  style={{ color: "#F29B9D" }}
                  className="wixui-rich-text__text"
                >
                  ■
                </span>
                サービス内容
                <br />
                <ul>
                  <li>レイアウト図</li>
                  <li>コーディネートワンポイント</li>
                  <li>セレクトアイテムリスト</li>
                </ul>
              </p>

              <p>
                <span
                  style={{ color: "#F29B9D" }}
                  className="wixui-rich-text__text"
                >
                  ■
                </span>
                サービス内容
                <br />
                <ul>
                  <li>１プランまでのご提案</li>
                  <li>作成期間はヒアリング終了より10～14営業日</li>
                  <li>
                    注）ご提案プラン・レイアウト・テイストに関する修正等は予定していない為事前にご了承願います。
                  </li>
                  <li>
                    <small>
                      ※上記に関しまして、事前に了承の上ご依頼頂いている上でのご契約となります。
                    </small>
                  </li>
                </ul>
              </p>
            </div>
            <div className="service_comp_inner">
              <p
                className="inner_pink_p"
                style={{ backgroundColor: "transparent" }}
              ></p>
              <img
                alt=""
                src="https://static.wixstatic.com/media/32eba4_db8db0b5870148d9bdf7a387862e16d8~mv2.png/v1/fill/w_373,h_140,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/ZOOM%2B%E3%81%AE%E3%82%B3%E3%83%94%E3%83%BC%E3%81%AE%E3%82%B3%E3%83%94%E3%83%BC2.png"
              />
              <p>
                <span
                  style={{ color: "#F29B9D" }}
                  className="wixui-rich-text__text"
                >
                  ■
                </span>
                オプション
                <br />
                <ul>
                  <li>お部屋追加作成　￥2.000/１部屋</li>
                  <li>追加プラン作成　￥3.000/１プラン</li>
                  <li>お急ぎ便（納期～３日） ￥15.000 </li>
                </ul>
              </p>

              <p>
                ※オプション代金はサービスご利用後のLINEトークルームにて事前に価格をご提示させていただきます。
                <br />
                サービス料金やオプション料金はすべてLINE内にて決済が可能です
              </p>
            </div>
          </div>

          <div className="service_comp">
            <div className="service_comp_inner">
              <p className="inner_pink_p">3Dインテリアコーディネート</p>
              <img
                alt=""
                src="https://static.wixstatic.com/media/32eba4_b2ba50b6478144aa9000a0b4a38969cb~mv2.jpg/v1/fill/w_369,h_140,al_c,q_80,usm_1.20_1.00_0.01,enc_auto/%E3%82%B9%E3%82%AF%E3%83%AA%E3%83%BC%E3%83%B3%E3%82%B7%E3%83%A7%E3%83%83%E3%83%88%202023-10-28%20024810.jpg"
              />
              <p>
                <span
                  style={{ color: "#F29B9D" }}
                  className="wixui-rich-text__text"
                >
                  ■
                </span>
                サービス内容
                <br />
                <ul>
                  <li>レイアウト図</li>
                  <li>コーディネートワンポイント</li>
                  <li>セレクトアイテムリスト</li>
                  <li>3Dインテリアイメージ</li>
                </ul>
              </p>

              <p>
                <span
                  style={{ color: "#F29B9D" }}
                  className="wixui-rich-text__text"
                >
                  ■
                </span>
                サービス内容
                <br />
                <ul>
                  <li>１プランまでのご提案</li>
                  <li>作成期間はヒアリング終了より10～14営業日</li>
                  <li>
                    注）ご提案プラン・レイアウト・テイストに関する修正等は予定していない為事前にご了承願います。
                  </li>
                  <li>
                    <small>
                      ※上記に関しまして、事前に了承の上ご依頼頂いている上でのご契約となります。
                    </small>
                  </li>
                </ul>
              </p>
            </div>
            <div className="service_comp_inner">
              <p
                className="inner_pink_p"
                style={{ backgroundColor: "transparent" }}
              ></p>
              <img
                alt=""
                src="https://static.wixstatic.com/media/32eba4_db8db0b5870148d9bdf7a387862e16d8~mv2.png/v1/fill/w_373,h_140,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/ZOOM%2B%E3%81%AE%E3%82%B3%E3%83%94%E3%83%BC%E3%81%AE%E3%82%B3%E3%83%94%E3%83%BC2.png"
              />
              <p>
                <span
                  style={{ color: "#F29B9D" }}
                  className="wixui-rich-text__text"
                >
                  ■
                </span>
                オプション
                <br />
                <ul>
                  <li>お部屋追加作成　￥4.000/１部屋</li>
                  <li>追加プラン作成　￥5.000/１プラン</li>
                  <li>お急ぎ便（納期～３日） ￥15.000 </li>
                </ul>
              </p>

              <p>
                ※オプション代金はサービスご利用後のLINEトークルームにて事前に価格をご提示させていただきます。{" "}
                <br />
                サービス料金やオプション料金はすべてLINE内にて決済が可能です
              </p>
            </div>
          </div>

          <div className="service_comp">
            <div className="service_comp_inner">
              <p
                className="inner_pink_p"
                style={{ backgroundColor: "#67a3d5" }}
              >
                3Dインテリアコーディネート
              </p>
              <img
                alt=""
                src="https://static.wixstatic.com/media/32eba4_162cdbfd1af54d749fd9cc8d3d558349~mv2.jpg/v1/fill/w_369,h_151,al_c,q_80,usm_1.20_1.00_0.01,enc_auto/%E3%83%AC%E3%83%B3%E3%82%BF%E3%83%AB%E3%82%B9%E3%83%9A%E3%83%BC%E3%82%B9%20(62).jpg"
              />
              <p>
                <span
                  style={{ color: "#F29B9D" }}
                  className="wixui-rich-text__text"
                >
                  ■
                </span>
                サービス内容
                <br />
                <ul>
                  <li>レイアウトプランボード</li>
                  <li>３Dスペースデザインパース</li>
                  <li>家具・備品セレクトアイテムリスト</li>
                </ul>
              </p>

              <p>
                <span
                  style={{ color: "#F29B9D" }}
                  className="wixui-rich-text__text"
                >
                  ■
                </span>
                サービス内容
                <br />
                <ul>
                  <li>１スペース１プランまでのご提案</li>
                  <li>作成期間はヒアリング終了～14営業日</li>
                </ul>
              </p>
            </div>
            <div className="service_comp_inner">
              <p
                className="inner_pink_p"
                style={{ backgroundColor: "transparent" }}
              ></p>
              <img
                alt=""
                src="https://static.wixstatic.com/media/32eba4_db8db0b5870148d9bdf7a387862e16d8~mv2.png/v1/fill/w_373,h_140,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/ZOOM%2B%E3%81%AE%E3%82%B3%E3%83%94%E3%83%BC%E3%81%AE%E3%82%B3%E3%83%94%E3%83%BC2.png"
              />
              <p>
                <span
                  style={{ color: "#F29B9D" }}
                  className="wixui-rich-text__text"
                >
                  ■
                </span>
                料金
                <br />
                <ul>
                  <li>１スペース￥10,900</li>
                </ul>
              </p>
            </div>
          </div>

          <div className="line_div">
            <div className="line_div_top">
              <img
                width="66"
                height="66"
                alt=""
                src="https://static.wixstatic.com/media/32eba4_e3afb18cd9594e22b9ee985079f76893~mv2.png/v1/fill/w_66,h_66,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/LINE_APP_edited.png"
              />
              <p>
                トークルームは公式LINEの友達追加で開設します
                <br />
                やり取りや決済まですべて
                <span style={{ color: "#1DB522" }}>LINE</span>で完結
              </p>
            </div>
            <div className="line_div_mid">
              <img
                alt=""
                src="https://static.wixstatic.com/media/4071c1_d9dde7972595462c90b72e2e2f30e8b1~mv2.png/v1/fill/w_243,h_447,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/iphone-gbfc35dabe_640.png"
              />
              <img
                alt=""
                src="https://static.wixstatic.com/media/4071c1_3eeb5aa5457f471595763c05e0241f02~mv2.png/v1/fill/w_243,h_447,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/iphone-gbfc35dabe_640%20(2).png"
              />
              <FontAwesomeIcon icon={faAnglesRight} />
              <img
                alt=""
                src="https://static.wixstatic.com/media/4071c1_3739efd5a1414c9e8b0831c156fc8301~mv2.png/v1/fill/w_243,h_447,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/iphone-gbfc35dabe_640%20(3).png"
              />
            </div>
          </div>

          <div className="service_goods_div">
            <h1>ー　予算とこだわりに合わせて選べる２つのコース　ー</h1>
            <p>【　サービス利用はコチラから　】</p>
            <div className="goods_shop">
              {productData.map((good, index) => (
                <div className="goods_shop_inner" key={index}>
                  <div className="goods_inner_top">
                    <h2>{good.name}</h2>
                    <div className="good_price_div">
                      <span className="surW0GO">￥</span>
                      <span className="sfCGvO2">{good.price}</span>
                    </div>
                    <p>{good.details}</p>
                    <div className="goods_buy_btn">
                      <Link
                        to={`/checkout/${good.id}`}
                        onClick={handleNavigate}
                      >
                        サービスを購入する
                      </Link>
                    </div>
                  </div>
                  <ul className="goods_ul">
                    {good.list.map((list, index) => (
                      <li key={index}>
                        <svg
                          role="img"
                          width="66"
                          height="22.5"
                          viewBox="0 0 24 24"
                        >
                          <path
                            class="s__7q13bT"
                            fill-rule="evenodd"
                            d="M10.785 14.961L8 12.176L8.707 11.469L10.785 13.547L15.293 9.039L16 9.746L10.785 14.961ZM12 4C7.582 4 4 7.582 4 12C4 16.418 7.582 20 12 20C16.419 20 20 16.418 20 12C20 7.582 16.419 4 12 4Z"
                          ></path>
                        </svg>
                        {list}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>

          <div className="space_box_div">
            <h3>​こんな場面で幅広く利用されています</h3>
            <div className="space_img_txt">
              <div
                className="space_inner"
                style={{
                  backgroundImage: `url("https://static.wixstatic.com/media/11062b_5398b38a0a0046e391d64d01904c5e51~mv2.jpg/v1/fill/w_300,h_200,al_c,q_80,usm_1.20_1.00_0.01,enc_auto/%E6%AE%B5%E3%83%9C%E3%83%BC%E3%83%AB%E3%82%92%E9%81%8B%E3%81%B6%E7%94%B7%E6%80%A7.jpg")`,
                }}
              >
                <p>引越</p>
              </div>
              <div
                className="space_inner"
                style={{
                  backgroundImage: `url("https://static.wixstatic.com/media/11062b_4bc19e52ef0241d58178437243ffd921~mv2.jpg/v1/fill/w_300,h_200,al_c,q_80,usm_1.20_1.00_0.01,enc_auto/%E6%AE%B5%E3%83%9C%E3%83%BC%E3%83%AB%E3%82%92%E9%81%8B%E3%81%B6%E7%94%B7%E6%80%A7.jpg")`,
                }}
              >
                <p>マイホーム新築</p>
              </div>
              <div
                className="space_inner"
                style={{
                  backgroundImage: `url("https://static.wixstatic.com/media/a7561e4109ae4688a4a95de4cf3c8529.jpg/v1/fill/w_300,h_200,al_c,q_80,usm_1.20_1.00_0.01,enc_auto/%E7%B5%B5%E7%94%BB%E3%81%AE%E5%A3%81.jpg")`,
                }}
              >
                <p>模様替え</p>
              </div>
              <div
                className="space_inner"
                style={{
                  backgroundImage: `url("https://static.wixstatic.com/media/11062b_2e1c6c7fc3b347b1a9b73bc8897896f8~mv2.jpg/v1/fill/w_300,h_200,al_c,q_80,usm_1.20_1.00_0.01,enc_auto/%E9%9B%BB%E6%B0%97%E3%83%89%E3%83%AA%E3%83%AB%E4%BD%9C%E6%A5%AD.jpg")`,
                }}
              >
                <p>リフォーム</p>
              </div>
              <div
                className="space_inner"
                style={{
                  backgroundImage: `url("https://static.wixstatic.com/media/11062b_2afc28dcea5e496ba56cf7ba0b9ca5f2~mv2.jpg/v1/crop/x_0,y_70,w_3024,h_2002/fill/w_300,h_200,al_c,q_80,usm_1.20_1.00_0.01,enc_auto/%E5%B1%8B%E5%86%85%E6%A4%8D%E7%89%A9.jpg")`,
                }}
              >
                <p>インテリア診断</p>
              </div>
              <div
                className="space_inner"
                style={{
                  backgroundImage: `url("https://static.wixstatic.com/media/11062b_64096d309e214bd8a4245dfb2ceddd88~mv2.jpg/v1/fill/w_300,h_200,al_c,q_80,usm_1.20_1.00_0.01,enc_auto/%E3%83%8E%E3%83%BC%E3%83%88%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%AB%E5%90%91%E3%81%8B%E3%81%86%E5%A5%B3%E6%80%A7.jpg")`,
                }}
              >
                <p>リモートワーク</p>
              </div>
            </div>
          </div>

          <div className="question_box_div">
            <h3>よくあるご質問</h3>
            <div className="ask_inner">
              <div className="question_box">
                <img
                  alt=""
                  src="https://static.wixstatic.com/media/4071c1_ef024ae531b14990b383888bd106ece3~mv2.png/v1/fill/w_40,h_40,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/1155829_edited.png"
                />
                <p>事前にサービス内容の詳細を確認したい</p>
              </div>
              <div
                id="comp-kw47gksh__item1"
                className="comp-kw47gksh AV56HG wixui-horizontal-line"
              ></div>
              <div className="ask_box">
                <img
                  alt=""
                  src="https://static.wixstatic.com/media/4071c1_af7eb313e07f42f68473b4763a0ab9c0~mv2.png/v1/fill/w_40,h_40,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/1155829_edited.png"
                />
                <p>
                  LINE登録よりお気軽にメッセージをお待ちしております。
                  <br />
                  その他ページ内「チャット」「お問い合わせ」をご利用のうえご連絡ください。随時ご対応させていただきます。
                </p>
              </div>
            </div>{" "}
            <div className="ask_inner">
              <div className="question_box">
                <img
                  alt=""
                  src="https://static.wixstatic.com/media/4071c1_ef024ae531b14990b383888bd106ece3~mv2.png/v1/fill/w_40,h_40,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/1155829_edited.png"
                />
                <p>決済方法が知りたい</p>
              </div>
              <div
                id="comp-kw47gksh__item1"
                className="comp-kw47gksh AV56HG wixui-horizontal-line"
              ></div>
              <div className="ask_box">
                <img
                  alt=""
                  src="https://static.wixstatic.com/media/4071c1_af7eb313e07f42f68473b4763a0ab9c0~mv2.png/v1/fill/w_40,h_40,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/1155829_edited.png"
                />
                <p>
                  サービスご利用開始はページ内「LINE登録」より各種クレジットカード及びお振込み払いをご利用いただけます。{" "}
                </p>
              </div>
            </div>{" "}
            <div className="ask_inner">
              <div className="question_box">
                <img
                  alt=""
                  src="https://static.wixstatic.com/media/4071c1_ef024ae531b14990b383888bd106ece3~mv2.png/v1/fill/w_40,h_40,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/1155829_edited.png"
                />
                <p>他の部屋もお願いする場合の料金は？</p>
              </div>
              <div
                id="comp-kw47gksh__item1"
                className="comp-kw47gksh AV56HG wixui-horizontal-line"
              ></div>
              <div className="ask_box">
                <img
                  alt=""
                  src="https://static.wixstatic.com/media/4071c1_af7eb313e07f42f68473b4763a0ab9c0~mv2.png/v1/fill/w_40,h_40,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/1155829_edited.png"
                />
                <p>
                  その他のお部屋もコーディネートご希望の場合は、オプションにて１部屋単位で承ります。{" "}
                </p>
              </div>
            </div>{" "}
            <div className="ask_inner">
              <div className="question_box">
                <img
                  alt=""
                  src="https://static.wixstatic.com/media/4071c1_ef024ae531b14990b383888bd106ece3~mv2.png/v1/fill/w_40,h_40,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/1155829_edited.png"
                />
                <p>プランは提案されたものが全てでしょうか？</p>
              </div>
              <div
                id="comp-kw47gksh__item1"
                className="comp-kw47gksh AV56HG wixui-horizontal-line"
              ></div>
              <div className="ask_box">
                <img
                  alt=""
                  src="https://static.wixstatic.com/media/4071c1_af7eb313e07f42f68473b4763a0ab9c0~mv2.png/v1/fill/w_40,h_40,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/1155829_edited.png"
                />
                <p>
                  基本的にはご要望をお伺いした上でプランニングし、3Dインテリアイメージを1案納品となります。{" "}
                </p>
              </div>
            </div>{" "}
            <div className="ask_inner">
              <div className="question_box">
                <img
                  alt=""
                  src="https://static.wixstatic.com/media/4071c1_ef024ae531b14990b383888bd106ece3~mv2.png/v1/fill/w_40,h_40,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/1155829_edited.png"
                />
                <p>意見や感想をお伝えする方法はありますか？</p>
              </div>
              <div
                id="comp-kw47gksh__item1"
                className="comp-kw47gksh AV56HG wixui-horizontal-line"
              ></div>
              <div className="ask_box">
                <img
                  alt=""
                  src="https://static.wixstatic.com/media/4071c1_af7eb313e07f42f68473b4763a0ab9c0~mv2.png/v1/fill/w_40,h_40,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/1155829_edited.png"
                />
                <p>サポート欄にて随時、ご意見ご感想を頂戴しております。 </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Service;
