import React from "react";
import { Link } from "react-router-dom";
import "../css/header.css";

function Header() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const toBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  return (
    <div className="header-container">
      <div className="header">
        <div className="header__inner__left">
          <img
            alt=""
            src="https://static.wixstatic.com/media/32eba4_1dee72b4d5ad4df582d8124d15da1326~mv2.png/v1/fill/w_53,h_53,al_c,q_85,usm_1.20_1.00_0.01,enc_auto/SHOWROOMINGNEW%E3%83%AD%E3%82%B4.png"
          />
          <div className="head_img_right_title">
            <p className="top_p">
              １部屋￥3,900～オンラインでかなうお部屋づくり
            </p>
            <p className="bot_p">SHOWROOMING</p>
          </div>
        </div>
        <div className="header__inner">
          <div className="wrp-navi">
            <ul>
              <li>
                <Link to="/" onClick={toTop}>
                  Home
                </Link>
              </li>
              <li>
                <Link to="#" onClick={toBottom}>
                  お問い合わせ
                </Link>
              </li>
              <li>
                <Link to="/privacy" onClick={toTop}>
                  プライバシーポリシー
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
